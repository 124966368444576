<script lang="ts" setup>
import BasicSelect from '~/components/form-elements/BasicSelect.vue'
import SpecialistAvatar from '~/components/specialist/SpecialistAvatar.vue'
import SpecialistGroupLabel from '~/components/specialist/SpecialistGroupLabel.vue'
import SpecialistSpecialLink from '~/components/specialist/SpecialistSpecialLink.vue'
import SpecialistSpecialities from '~/components/specialist/SpecialistSpecialities.vue'
import AuthScope from '~/enums/auth-scope'
import ConsultationStatus from '~/enums/consultation-status'
import Page from '~/enums/page'
import SpecialistCommand from '~/enums/specialist-command'
import { useConsultationsStore } from '~/store/consultations'
import type { ConsultationData } from '~/types/consultations'
import type { SelectOption } from '~/types/form-elements'

const emit = defineEmits<{
  (e: 'reschedule'): void
}>()

const props = defineProps<{
  authScope: AuthScope
  consultationData: ConsultationData
}>()

const consultationsStore = useConsultationsStore()
const router = useRouter()

const attributes = computed(() => props.consultationData.attributes)
const fullName = computed(() => {
  if (props.authScope === AuthScope.Specialist) {
    return `${order.value.attributes.shippingFirstName} ${order.value.attributes.shippingLastName}`
  } else {
    return [specialist.value.lastName, specialist.value.attributes.firstName, specialist.value.attributes.patronymic]
      .filter(Boolean)
      .join(' ')
  }
})
const images = computed(() => specialist.value?.relationships.images?.data)
const isAwaitingCompletion = computed(() => {
  if (
    statusId.value === ConsultationStatus.Confirmed
    && (
      props.consultationData.attributes.startedAt === null
      || new Date(props.consultationData.attributes.startedAt) <= new Date()
    )
  ) {
    return true
  }

  return false
})
const relationships = computed(() => props.consultationData.relationships) as any
const optionsSelect = computed<SelectOption[]>(() => {
  const options: SelectOption[] = []

  if (
    props.authScope === AuthScope.Client
    && statusId.value !== ConsultationStatus.Canceled
    && statusId.value !== ConsultationStatus.Completed
    && (
      props.consultationData.attributes.startedAt === null
      || new Date(props.consultationData.attributes.startedAt) >= newTimeWithTwoHours.value
    )
  ) {
    options.push(
      {
        name: SpecialistCommand.getName(SpecialistCommand.Reschedule),
        value: SpecialistCommand.Reschedule
      }
    )
  }

  if (props.authScope === AuthScope.Client && statusId.value === ConsultationStatus.Completed) {
    options.push({ name: SpecialistCommand.getName(SpecialistCommand.Repeat), value: SpecialistCommand.Repeat })
  }

  if (
    (
      props.consultationData.attributes.startedAt === null
      || new Date(props.consultationData.attributes.startedAt) >= newTimeWithTwoHours.value
    ) && (
      statusId.value === ConsultationStatus.Booked
      || statusId.value === ConsultationStatus.Confirmed
    )
  ) {
    options.push({ name: SpecialistCommand.getName(SpecialistCommand.Cancel), value: SpecialistCommand.Cancel })
  }

  return options
})
const order = computed(() => relationships.value.order?.data) as any
const newTimeWithTwoHours = computed(() => new Date((new Date()).getTime() + 2 * 60 * 60 * 1000))
const service = computed(() => relationships.value.service?.data) as any
const specialist = computed(() => relationships.value.specialist?.data)
const specialities = computed(() => specialist.value?.relationships.specialities?.data)
const startedAt = computed(() => attributes.value.startedAt)
const statusId = computed(() => props.consultationData.attributes.statusId)

function onSelected (x: SelectOption) {
  switch (x.value)
  {
    case SpecialistCommand.Cancel:
      consultationsStore.cancelConsultation(props.consultationData.id, props.authScope)
      break;
    case SpecialistCommand.Repeat:
      router.push(`${Page.Specialist}appointment/${specialist.value.id}`)
      break;
    case SpecialistCommand.Reschedule:
      emit('reschedule');
      break;
  }
}
</script>

<template>
  <div class="box-border size-full rounded-3xl bg-white">
    <div class="flex h-full flex-col">
      <div class="flex items-center justify-between border-b border-[#eee] p-2">
        <div class="flex items-center text-sm font-medium md:text-base md:font-normal">
          <template v-if="startedAt">
            <b>{{ startedAt }} (МСК)</b>

            <div class="mx-2 size-1 rounded-full bg-main-gray-low" />
          </template>

          <span>{{ service.attributes.name }} / {{ Math.floor(order.attributes.totalInKopecks / 100) }} ₽</span>
        </div>

        <div class="flex items-center">
          <StatusDecorator>
            <Status
              :color="ConsultationStatus.getColor(attributes.statusId)"
              :title="ConsultationStatus.getName(attributes.statusId)"
            />
          </StatusDecorator>

          <SpecialistSpecialLink
            v-if="isAwaitingCompletion && authScope === AuthScope.Client"
            class="ml-3"
            :title="SpecialistCommand.getName(SpecialistCommand.Complete)"
            @click.prevent="consultationsStore.completeConsultation(consultationData.id, authScope)"
          />

          <SpecialistSpecialLink
            v-if="statusId === ConsultationStatus.Booked && authScope === AuthScope.Specialist"
            class="ml-3"
            :title="SpecialistCommand.getName(SpecialistCommand.Accept)"
            @click.prevent="consultationsStore.acceptConsultation(consultationData.id)"
          />

          <BasicSelect
            :class="{ 'pointer-events-none hidden cursor-default': optionsSelect.length === 0 }"
            icon="outlined/dropdown"
            :is-border="false"
            :is-radio-button="false"
            :options="optionsSelect"
            @selected="onSelected"
          />
        </div>
      </div>

      <div class="flex items-start p-4">
        <SpecialistAvatar
          class="mr-4 w-[68px]"
          :images-data="authScope === AuthScope.Client ? images : []"
          size="100x100"
        />

        <div>
          <h1 class="mb-2 text-base font-medium text-main-dark md:text-[22px]">
            {{ fullName }}
          </h1>

          <div class="mb-3 text-xs text-main-aluminum md:text-base">
            <template v-if="authScope === AuthScope.Specialist">
              Клиент
            </template>
            <SpecialistSpecialities v-else :specialities="specialities" />
          </div>

          <div class="flex flex-wrap items-center">
            <template v-if="authScope === AuthScope.Client">
              <SpecialistGroupLabel
                class="flex flex-wrap items-center justify-center"
                :experience="specialist.attributes.experience"
              />
            </template>
            <div v-else class="flex flex-wrap text-sm md:text-base [&_a]:!text-main-dark">
              <a v-if="order.attributes.shippingEmail" class="mr-3" :href="`mailto:${order.attributes.shippingEmail}`">
                {{ order.attributes.shippingEmail }}
              </a>

              <a v-if="order.attributes.shippingPhoneNumber" :href="`tel:${order.attributes.shippingPhoneNumber}`">
                {{ order.attributes.shippingPhoneNumber }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
